import $ from 'jquery';
import './scss/style.scss';
import slick from 'slick-carousel';
import 'slick-carousel/slick/slick.css';

// Home Carousel
$('.carousel').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
  autoplaySpeed: 4000,
  autoplay: true,
  arrows: false,
  dots: true,
});

// Menu button
$('.header-nav-btn').click(function(){
  console.log('click')
  const has = $(this).hasClass('open')
  const menu = $('.header-nav .header-nav-container')
  if(has) {
    $(this).removeClass('open')
    menu.removeClass('open')
  } else {
    $(this).addClass('open')
    menu.addClass('open')
  }
})

// Modal open and close
const modal = $('.modal')
const page = $('body') 
$('.infos-btn').click(function(){
  modal.addClass('open')
  page.addClass('modal-open')
})
$('.modal-close-btn').click(function(){
  modal.removeClass('open')
  page.removeClass('modal-open')
})

// Filter
$('.filter-item').click(function(){
  const attr = $(this).attr('data-filter')
  const item = $('.grid-item, .list-item')
  const current = $(`.grid-item.${attr}, .list-item.${attr}`)

  $('.filter-item').removeClass('current')
  $(this).addClass('current')

  item.hide()
  current.show()
})

// Click work grid
$('.grid-item').click(function(){
  $('.container').removeClass('active')
  $(this).find('.container').addClass('active')
})


// select video element
const vid = $('#scroll-video')[0];
const frame = document.body.offsetHeight / 4

vid.pause();

window.onscroll = function(){
  vid.pause();
};

setInterval(function(){
  vid.currentTime = window.pageYOffset/frame;
}, 40);